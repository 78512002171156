import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'loading',
    pathMatch: 'full',
  },{
    path: 'login',
    loadChildren: './login/login.module#LoginModule'
  }
  ,
  {
    path: 'loading',
    loadChildren: './loading/loading.module#LoadingModule'
  },
 
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: 'email',
        loadChildren: './views/email/email.module#EmailModule'
      },
      {
        path: 'dashboard',
        loadChildren: './views/dashboard/dashboard.module#DashboardModule'
      },
      {
        path: 'agenda',
        loadChildren: './views/agenda/agenda.module#AgendaModule'
      },
      {
        path: 'dieta',
        loadChildren: './views/dieta/dieta.module#DietaModule'
      },
      {
        path: 'empresa',
        loadChildren: './views/empresa/empresa.module#EmpresaModule'
      },
      {
        path: 'exame',
        loadChildren: './views/exame/exame.module#ExameModule'
      },
      {
        path: 'relatorio',
        loadChildren: './views/relatorio/relatorio.module#RelatorioModule'
      },
      {
        path: 'operadora',
        loadChildren: './views/operadora/operadora.module#OperadoraModule'
      },
      {
        path: 'paciente',
        loadChildren: './views/paciente/paciente.module#PacienteModule'
      },
      {
        path: 'usuario',
        loadChildren: './views/usuario/usuario.module#UsuarioModule'
      }, {
        path: 'artigo',
        loadChildren: './views/artigo/artigo.module#ArtigoModule'
      },{
        path: 'configuracoes',
        loadChildren: './views/configuracoes/configuracoes.module#ConfiguracoesModule'
      },{
        path: 'arquivos',
        loadChildren: './views/arquivos/arquivos.module#ArquivosModule'
      },
      

    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled', // or 'top'
    anchorScrolling: 'enabled',
    scrollOffset: [0, 64] // [x, y] - adjust scroll offset
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
