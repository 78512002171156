import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from './../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class WsService {
  public headers = new HttpHeaders();
  constructor(private http: HttpClient) {
    this.headers = this.headers.set("Content-Type","application/json; charset=UTF-8");
  }
  requerimento(dados){
    return new Promise(resolve => {
      this.http.post(environment.urlApi, JSON.stringify(dados),{
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
      .subscribe(res => {
        resolve(res);
      }, (err) => {
      });
    });
  }
  pdf(dados){
    return new Promise(resolve => {
      this.http.post(environment.urlPDF, JSON.stringify(dados),{
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
      .subscribe(res => {
        resolve(res);
      }, (err) => {
      });
    });
  }
  img(dados){
    return new Promise(resolve => {
      this.http.post(environment.urlIMG, JSON.stringify(dados),{
        headers: { 'Content-Type': 'application/json;charset=utf-8' }
      })
      .subscribe(res => {
        resolve(res);
      }, (err) => {
      });
    });
  }
}
