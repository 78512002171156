import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LocalStorage } from '@ngx-pwa/local-storage';


@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(private router: Router,protected storage: LocalStorage) { }

  ngOnInit() {
    this.storage.getItem('usuario').subscribe((data) =>{
      if (data == null){
        this.router.events.subscribe((evt) => {
          if (!(evt instanceof NavigationEnd)) {
            return;
          }
          window.scrollTo(0, 0);
        });
      } else if (data['privilegio'] == 1) {
        this.router.navigateByUrl('/paciente');
      }
    });
   
  }
}
