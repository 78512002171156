import { Component, ViewEncapsulation, AfterViewInit, ViewChild, OnInit, Renderer2 } from '@angular/core';
import { navItems, navExames, navNeoVirtua, navNutricionista, navPersonal, navPsicologo, navRecepcao } from './../../_nav';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Router } from '@angular/router';
import { ToasterService, ToasterConfig } from 'angular2-toaster/angular2-toaster';
import { Md5 } from 'ts-md5/dist/md5';
import { WsService } from '../../ws.service';
import { ViewportScroller } from "@angular/common";
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css',
    './../../../scss/vendors/toastr/toastr.scss',
    './../../../scss/vendors/bs-datepicker/bs-datepicker.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ToasterService]
})
export class DefaultLayoutComponent implements AfterViewInit, OnInit {
  @ViewChild('horariosModal') horariosModal: any;
  constructor(private localeService: BsLocaleService, protected storage: LocalStorage, public router: Router, toasterService: ToasterService, public ws: WsService, private scroller: ViewportScroller, private renderer: Renderer2) {
    this.toasterService = toasterService;
    localeService.use('pt-br');
    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = document.body.classList.contains('sidebar-minimized');
    });
    this.changes.observe(<Element>this.element, {
      attributes: true
    });
    this.storage.getItem('token').subscribe((tk) => {
      if (tk != null) {
        this.token = tk;
        this.storage.getItem('usuario').subscribe((data) => {
          console.log(data)
          if (data != null) {
            //this.usuario = data;
            this.setUsuario(data);
            this.loop();
            this.atualizarAgenda();
            this.getUsuariosChat();
            this.getMensagens();
            switch (data['funcao']+'') {
              case '1': this.navItems = navItems; console.log ;break;
              case '2': this.navItems = navRecepcao; break;
              case '3': this.navItems = navNutricionista; break;
              case '4': this.navItems = navPsicologo; break;
              case '7': this.navItems = navPersonal; break;
              case '8': this.navItems = navExames; break;
              case '9': this.navItems = navNeoVirtua; break;
            }
            console.log(this.navItems);
            console.log(data['funcao']);
          }
        });
      }
    });
  }
  ngOnInit(): void {

    this.globalListenFunc = this.renderer.listen('document', 'keypress', e => {
      if (e.ctrlKey && e.key == 'a') {
        this.router.navigate(['/agenda'], { queryParams: { abrir: 'novoAgendamento' } });
      }
    });
    

  }
  globalListenFunc: Function;
  @ViewChild('ancora') private scrollChat: any;
  ngAfterViewInit(): void {
    document.getElementById('minimizer').click();
    this.horariosModal.config.ignoreBackdropClick = true;
  }
  scroll(el: HTMLElement, el2: HTMLElement) {
    el.scrollIntoView();
    el2.scrollIntoView();
  }
  public nsenha = "";
  public timer: any;
  public csenha = "";
  public token = "";
  public agenda = [];
  public navItems = [];
  public bsDia = new Date();
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public hoje = new Date();
  public element: HTMLElement = document.body;
  private toasterService: ToasterService;
  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 5000
    });
  public usuario: any = {
    nome: "",
    idUsuario: "",
    tipo: 0
  }
  public classUserList = "people-list d-block";
  public classChat = "chat d-none";
  public usuarios: any = [];
  public chaveChat = false;
  escolhorConversa() {
    this.para.idUsuario = 0;
    this.chaveChat = false;
    this.classUserList = "people-list d-block";
    this.classChat = "chat d-none";
    setTimeout(() => {
      document.getElementById('btScroll').click();
    },
      500);
  }
  mostrarNotificacao(nota) {
    this.alerta(3, nota.nome, nota.mensagem);
    let ids = [nota.idChat];
    this.menssagensLidas(ids);
  }
  menssagensLidas(ids) {
    let post = {
      token: this.token,
      opt: 'marcarComoLido',
      ids: ids
    }
    this.ws.requerimento(post).then(() => {
      this.atualizarAgenda();
      this.getUsuariosChat();
      this.getMensagens();
    });
  }
  todasLidas() {
    let ids = [];
    for (let item of this.notificacao) {
      ids.push(item.idChat);
    }
    this.menssagensLidas(ids);
    this.alerta(3, "Chat", "Todas marcadas como lida.");
  }
  edit(valor) {
    this.usuarios = valor;
  }
  polling: any;
  loop() {
    this.polling = setInterval(() => {
      let post = {
        token: this.token,
        opt: 'statusOnline',
        idUsuario: this.usuario.idUsuario,
        ultimaMensagem: ""
      }
      if (this.minhasMensagens.length != undefined) {
        if (this.minhasMensagens.length == 0) {
          this.getMensagens();
        }
      }
      if (this.minhasMensagens[this.minhasMensagens.length - 1]) {
        this.getMensagensNovas(this.minhasMensagens[this.minhasMensagens.length - 1].dataRegistro);
      }
      this.ws.requerimento(post).then((data) => {
        if (data[0][0] = "00000") {

          if (JSON.stringify(this.usuarios) != JSON.stringify(data[1])) {
            this.usuarios = data[1];
            setTimeout(() => {
              document.getElementById('btScroll').click();
            },
              500);
          }

        }
      });
    }, 5000);
  }
  public para: any = {
    idUsuario: 0,
    nome: null,
    online: null,
    sexo: null
  }
  public mensagem = "";
  public mensagens: any = [];
  public minhasMensagens: any = [];
  public notificacao: any = [];
  abrirChat(usuario) {
    this.para = usuario;
    this.chaveChat = true;
    this.classUserList = "people-list d-none";
    this.classChat = "chat d-block";
    this.mensagens = this.minhasMensagens.filter(msg =>
      msg.para == usuario.idUsuario && msg.de == this.usuario.idUsuario ||
      msg.para == this.usuario.idUsuario && msg.de == usuario.idUsuario
    );
    let nLidas = []
    for (let i of this.mensagens) {
      if (i.lido == 0) {
        nLidas.push(i.idChat);
      }
    }
    if (nLidas.length > 0) {
      // this.menssagensLidas(nLidas);
    }
    setTimeout(() => {
      document.getElementById('btScroll').click();
    },
      500);
  }
  getMensagensNovas(last) {
    let post = {
      para: this.usuario.idUsuario,
      opt: "getMensagens",
      token: this.token,
      last: last
    }
    this.ws.requerimento(post).then((retorno: any) => {
      if (retorno['result'].length > 0) {
        Array.prototype.push.apply(this.minhasMensagens, retorno['result']);
        let lista = [];
        for (let nota of this.minhasMensagens.filter(msg => msg.lido == 0)) {
          if (this.usuarios.find(usuario => usuario.idUsuario == nota.de) != undefined) {
            let saida = {
              idChat: nota.idChat,
              nome: this.usuarios.find(usuario => usuario.idUsuario == nota.de).nome,
              sexo: this.usuarios.find(usuario => usuario.idUsuario == nota.de).sexo,
              mensagem: nota.mensagem,
              dataRegistro: nota.dataRegistro
            }
            lista.push(saida);
          }
        }
        this.notificacao = lista;
        if (this.para.idUsuario != 0) {
          this.abrirChat(this.para);
        }

      }
    });
  }

  getMensagens() {
    let post = {
      para: this.usuario.idUsuario,
      opt: "getMensagens",
      token: this.token
    }
    this.ws.requerimento(post).then((retorno: any) => {
      setTimeout(() => {
        this.minhasMensagens = retorno['result'];
        let lista = [];
        for (let nota of this.minhasMensagens.filter(msg => msg.lido == 0)) {
          if (this.usuarios.find(usuario => usuario.idUsuario == nota.de) != undefined) {
            let saida = {
              idChat: nota.idChat,
              nome: this.usuarios.find(usuario => usuario.idUsuario == nota.de).nome,
              sexo: this.usuarios.find(usuario => usuario.idUsuario == nota.de).sexo,
              mensagem: nota.mensagem,
              dataRegistro: nota.dataRegistro
            }
            lista.push(saida);
          }
        }
        this.notificacao = lista;
      },
        3000);
    });
  }
  salvarMensagem() {
    if (this.mensagem != "") {
      let post = {
        de: this.usuario.idUsuario,
        para: this.para.idUsuario,
        mensagem: this.mensagem,
        opt: "salvarMensagem",
        token: this.token
      }
      this.ws.requerimento(post).then((retorno: any) => {
        if (retorno[0] == '00000') {
          let r = new Date();
          let YYYY = r.getFullYear();
          let MM = ((r.getMonth() + 1 < 10) ? '0' : '') + (r.getMonth() + 1);
          let DD = ((r.getDate() < 10) ? '0' : '') + r.getDate();
          let HH = ((r.getHours() < 10) ? '0' : '') + r.getHours();
          let mm = ((r.getMinutes() < 10) ? '0' : '') + r.getMinutes();
          let ss = ((r.getSeconds() < 10) ? '0' : '') + r.getSeconds();
          let msg = {
            dataRegistro: YYYY + '-' + MM + '-' + DD + ' ' + HH + ':' + mm + ':' + ss,
            de: this.usuario.idUsuario,
            idChat: "0",
            lido: "0",
            mensagem: this.mensagem,
            para: this.para.idUsuario
          }

          this.mensagens.push(msg);
          //this.abrirChat(this.para);
          setTimeout(() => {
            document.getElementById('btScroll').click();

          },
            500);
          this.mensagem = "";
        } else {
          this.alerta(1, "Chat", "erro no servidor, não foi possível mandar sua mensagem.");
        }

      });

    } else {
      this.alerta(1, "Chat", "Escreva para enviar");
    }
  }

  public abrirAgendado(agendamento) {

    this.router.navigate(['/dashboard']).then(() => {
      this.router.navigate(['/paciente'], { queryParams: { idPaciente: agendamento['idPaciente'] } });
    });


  }
  public getUsuariosChat() {
    let post = {
      token: this.token,
      opt: 'getUsuariosChat',
      idUsuario: this.usuario.idUsuario
    }
    this.ws.requerimento(post).then((retorno: any) => {
      this.usuarios = retorno;
    });
  }
  public atualizarAgenda() {
    let post = {
      token: this.token,
      idUsuario: this.usuario['idUsuario'],
      opt: 'agendaUsuario'
    }
    this.ws.requerimento(post).then((retorno: any) => {
      if (retorno != null) {
        let listaAgendamento = [];
        for (let item of retorno) {
          item.hora = new Date(
            parseInt(item.hora.split(",")[0]),
            parseInt(item.hora.split(",")[1]),
            parseInt(item.hora.split(",")[2]),
            parseInt(item.hora.split(",")[3]),
            parseInt(item.hora.split(",")[4]), 0, 0
          );
          listaAgendamento.push(item);
        }
        this.agenda = listaAgendamento;
      }
    });
  }
  public alerta(tipo, titulo, msg) {
    let tipos = ['success', 'error', 'warning', 'info', 'primary'];
    this.toasterService.pop(tipos[tipo], titulo, msg);
  }
  public sair() {
    this.storage.clear().subscribe(() => {
      clearInterval(this.polling);
      this.router.navigateByUrl('/loading');
    });
  }
  trocarSenha(mod) {
    if (this.nsenha.trim() == "" || this.csenha.trim() == "") {
      this.alerta(1, 'Senha', 'todos os campos são obrigatórios');
    } else if (this.nsenha.trim() != this.csenha.trim()) {
      this.alerta(2, 'Senha', 'Os campos devem ser iguais.');
    } else if (this.nsenha.length < 6) {
      this.alerta(2, 'Senha', 'a senha deve ter no minimo 6 caracteres.');
    } else if (Md5.hashStr(this.nsenha) == this.usuario.senha) {
      this.alerta(2, 'Senha', 'a senha não deve ser igual a senha atual.');
    } else {
      let nsenha = Md5.hashStr(this.nsenha);
      let post: any = { opt: "trocarSenha", usuario: this.usuario, senha: nsenha, token: this.token };
      this.ws.requerimento(post).then((data) => {
        if (data[0] == "00000") {
          this.nsenha = "";
          this.csenha = "";
          this.alerta(0, 'Senha', 'Senha alterada com sucesso.');
          mod.hide();
        } else {
          this.alerta(1, 'Senha', 'Falha ao trocar a senha, tente mais tarde.');
        }
      });
    }
  }

  // Configurações

  public dados: any;
  public ano = new Date().getFullYear();
  public operadoras: any;
  //public usuario: any = { operadoras: [] };
  public opedadorasDoUsuario: any;
  public listaOperadoras: any;
  public duracao = 0;
  public chaveDuracao: Boolean = true;
  public minDate: Date = new Date(this.ano, 5, 10);
  public maxDate: Date = new Date(this.ano, 9, 15);
  public bsValue: Date = new Date();
  public bsRangeValue: any = [new Date(), new Date()];
  public hstep: number = 1;
  public mstep: number = 5;
  public salvarIntervaloDiaEspecifico = false;
  public ismeridian: boolean = false;
  public isEnabled: boolean = true;
  public mytime: Date = new Date();
  public inicio: Date = new Date(this.ano, 11, 1, 0, 0, 0, 0);
  public fim: Date = new Date(this.ano, 11, 1, 0, 0, 0, 0);
  public horario = [
    { label: "Tempo 15min", value: "15" },
    { label: "Tempo 20min", value: "20" },
    { label: "Tempo 25min", value: "25" },
    { label: "Tempo 30min", value: "30" },
    { label: "Tempo 35min", value: "35" },
    { label: "Tempo 40min", value: "40" },
    { label: "Tempo 45min", value: "45" },
    { label: "Tempo 50min", value: "50" },
    { label: "Tempo 55min", value: "55" },
    { label: "Tempo 60min", value: "60" }


  ];
  public options: any = {
    hstep: [1, 2, 3],
    mstep: [1, 5, 10, 15, 25, 30]
  };
  public modal = {
    obj: null,
    dia: null,
    titulo: null,
    intervalo: null
  }
  public chaveInervaloDia = {
    intervalo1: false,
    intervalo2: false,
    intervalo: true
  }
  public diaEspecifico = {
    data: new Date(),
    intervalo1: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
    intervalo2: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
    duracaoIntervalo1: "15",
    duracaoIntervalo2: "15"
  }
  public editarDiaEspecifico = {
    data: new Date(),
    intervalo1: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
    intervalo2: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
    duracaoIntervalo1: "15",
    duracaoIntervalo2: "15"
  }
  public configuracao = {
    diasDaSemana: {
      seg: {
        ativo: false,
        intervalo1: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
        intervalo2: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
        duracaoIntervalo1: "15",
        duracaoIntervalo2: "15"
      },
      ter: {
        ativo: false,
        intervalo1: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
        intervalo2: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
        duracaoIntervalo1: "15",
        duracaoIntervalo2: "15"
      },
      qua: {
        ativo: false,
        intervalo1: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
        intervalo2: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
        duracaoIntervalo1: "15",
        duracaoIntervalo2: "15"
      },
      qui: {
        ativo: false,
        intervalo1: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
        intervalo2: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
        duracaoIntervalo1: "15",
        duracaoIntervalo2: "15"
      },
      sex: {
        ativo: false,
        intervalo1: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
        intervalo2: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
        duracaoIntervalo1: "15",
        duracaoIntervalo2: "15"
      },
      sab: {
        ativo: false,
        intervalo1: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
        intervalo2: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
        duracaoIntervalo1: "15",
        duracaoIntervalo2: "15"
      }
    },
    diaEspecifico: [],
    intervalo: [],
    diaEspecificoDeTrabalho: []
  }
  public chaveDia(valor, valor2) {
    if (valor == 1) {
      this.salvarIntervaloDiaEspecifico = true;
      this.chaveInervaloDia = {
        intervalo1: true,
        intervalo2: false,
        intervalo: false
      }
      //this.zerarDia(1,0);
    }
    if (valor == 2) {
      this.salvarIntervaloDiaEspecifico = true;
      this.chaveInervaloDia = {
        intervalo1: false,
        intervalo2: true,
        intervalo: false
      }
      //this.zerarDia(2,0);
    }
    if (valor == 3) {
      if (valor2 == 1) {
        if (this.diaEspecifico.intervalo1.inicio.getHours() == 0 &&
          this.diaEspecifico.intervalo1.fim.getHours() == 0) {
          this.alerta(2, "intervalo1", "defina um horário");
        } else {
          this.salvarIntervaloDiaEspecifico = true;
          this.chaveInervaloDia = {
            intervalo1: false,
            intervalo2: false,
            intervalo: true
          }
        }
      } else if (valor2 == 2) {
        if (this.diaEspecifico.intervalo2.inicio.getHours() == 0 &&
          this.diaEspecifico.intervalo2.fim.getHours() == 0) {
          this.alerta(2, "intervalo2", "defina um horário");
        } else {
          this.salvarIntervaloDiaEspecifico = true;
          this.chaveInervaloDia = {
            intervalo1: false,
            intervalo2: false,
            intervalo: true
          }
        }
      } else {
        this.chaveInervaloDia = {
          intervalo1: false,
          intervalo2: false,
          intervalo: true
        }
      }


    }
  }
  public zerarDia(valor, valor2) {
    if (valor == 1) {
      this.diaEspecifico.intervalo1 = { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) }

    }
    if (valor == 2) {
      this.diaEspecifico.intervalo2 = { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) }
    }
    if (valor2 == 1) {
      this.chaveInervaloDia = {
        intervalo1: false,
        intervalo2: false,
        intervalo: true
      }
    }
  }
  setUsuario(usuario) {
    let post = {
      opt: "getUsuario",
      idUsuario: usuario.idUsuario,
      token: this.token
    }
    this.ws.requerimento(post).then((retorno) => {
      this.usuario = retorno[0];
      this.prepararConfiguracao(retorno[0]);

    });
   
  }
  prepararConfiguracao(usuario) {
    if (this.usuario.configuracao != null) {
      let indice = ['seg', 'ter', 'qua', 'qui', 'sex', 'sab'];
      if (usuario.configuracao.trim() != "") {
        this.configuracao = JSON.parse(usuario.configuracao);
        for (let i of indice) {
          this.configuracao.diasDaSemana[i].intervalo1.inicio = new Date(JSON.parse(usuario.configuracao).diasDaSemana[i].intervalo1.inicio);
          this.configuracao.diasDaSemana[i].intervalo1.fim = new Date(JSON.parse(usuario.configuracao).diasDaSemana[i].intervalo1.fim);
          this.configuracao.diasDaSemana[i].intervalo2.inicio = new Date(JSON.parse(usuario.configuracao).diasDaSemana[i].intervalo2.inicio);
          this.configuracao.diasDaSemana[i].intervalo2.fim = new Date(JSON.parse(usuario.configuracao).diasDaSemana[i].intervalo2.fim);
        }
        for (let i in this.configuracao.diaEspecifico) {
          this.configuracao.diaEspecifico[i] = new Date(this.configuracao.diaEspecifico[i]);
        }
        for (let i in this.configuracao.intervalo) {
          this.configuracao.intervalo[i][0] = new Date(this.configuracao.intervalo[i][0]);
          this.configuracao.intervalo[i][1] = new Date(this.configuracao.intervalo[i][1]);
        }
        for (let i in this.configuracao.diaEspecificoDeTrabalho) {
          this.configuracao.diaEspecificoDeTrabalho[i].data = new Date(this.configuracao.diaEspecificoDeTrabalho[i].data);
          this.configuracao.diaEspecificoDeTrabalho[i].intervalo1.inicio = new Date(this.configuracao.diaEspecificoDeTrabalho[i].intervalo1.inicio);
          this.configuracao.diaEspecificoDeTrabalho[i].intervalo1.fim = new Date(this.configuracao.diaEspecificoDeTrabalho[i].intervalo1.fim);
          this.configuracao.diaEspecificoDeTrabalho[i].intervalo2.inicio = new Date(this.configuracao.diaEspecificoDeTrabalho[i].intervalo2.inicio);
          this.configuracao.diaEspecificoDeTrabalho[i].intervalo2.fim = new Date(this.configuracao.diaEspecificoDeTrabalho[i].intervalo2.fim);
        }
        this.ajusteDosDadosNoArray(this.configuracao.diaEspecificoDeTrabalho)
        if (this.configuracao.diaEspecificoDeTrabalho.length > this.dados.length) {
          this.configuracao.diaEspecificoDeTrabalho = this.dados;
          // this.salvar();
        }
      }
    } else {
      this.configuracao = {
        diasDaSemana: {
          seg: {
            ativo: false,
            intervalo1: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
            intervalo2: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
            duracaoIntervalo1: "15",
            duracaoIntervalo2: "15"
          },
          ter: {
            ativo: false,
            intervalo1: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
            intervalo2: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
            duracaoIntervalo1: "15",
            duracaoIntervalo2: "15"
          },
          qua: {
            ativo: false,
            intervalo1: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
            intervalo2: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
            duracaoIntervalo1: "15",
            duracaoIntervalo2: "15"
          },
          qui: {
            ativo: false,
            intervalo1: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
            intervalo2: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
            duracaoIntervalo1: "15",
            duracaoIntervalo2: "15"
          },
          sex: {
            ativo: false,
            intervalo1: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
            intervalo2: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
            duracaoIntervalo1: "15",
            duracaoIntervalo2: "15"
          },
          sab: {
            ativo: false,
            intervalo1: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
            intervalo2: { inicio: new Date(this.ano, 11, 1, 0, 0, 0, 0), fim: new Date(this.ano, 11, 1, 0, 0, 0, 0) },
            duracaoIntervalo1: "15",
            duracaoIntervalo2: "15"
          }
        },
        diaEspecifico: [],
        intervalo: [],
        diaEspecificoDeTrabalho: []
      }
    }
  }
  custom_sort(a, b) {
    return new Date(a.data).getTime() - new Date(b.data).getTime();
  }
  ajusteDosDadosNoArray(tableData) {

    tableData.sort(this.custom_sort)
    let dados = [];
    let hj = new Date();
    hj.setDate(hj.getDate() - 1);
    for (let i of tableData) {
      let d = new Date(i['data'])
      if (d > hj) {
        dados.push(i);
      }
    }
    dados.sort(this.custom_sort)
    this.dados = dados;
  }
  setIntervalor(mod, dia, titulo, intervalo) {
    this.modal.obj = mod;
    this.modal.dia = dia;
    this.modal.titulo = titulo;
    this.modal.intervalo = intervalo;
    if (intervalo == "intervalo1") {
      this.inicio = new Date(this.ano, 11, 1, 8, 0, 0, 0);
      this.fim = new Date(this.ano, 11, 1, 11, 45, 0, 0);
    }
    if (intervalo == "intervalo2") {
      this.inicio = new Date(this.ano, 11, 1, 13, 0, 0, 0);
      this.fim = new Date(this.ano, 11, 1, 17, 0, 0, 0);
    }
    this.modal.obj.config.ignoreBackdropClick = true;
    this.modal.obj.show();
  }
  salvarIntervalo() {
    let i = new Date(this.ano, 11, 1, 0, 0, 0, 0);
    if ((this.inicio.getTime() == i.getTime()) && (this.fim.getTime() == i.getTime())) {
      this.configuracao.diasDaSemana[this.modal.dia][this.modal.intervalo].inicio = new Date(this.ano, 11, 1, this.inicio.getHours(), this.inicio.getMinutes(), 0, 0);
      this.configuracao.diasDaSemana[this.modal.dia][this.modal.intervalo].fim = new Date(this.ano, 11, 1, this.fim.getHours(), this.fim.getMinutes(), 0, 0);
      this.modal.obj.hide();
      this.alerta(2, "intervalo", "O intervalo foi zerado");
    } else {
      if (this.inicio < this.fim) {
        let max = new Date(this.ano, 11, 1, 7, 0, 0, 0);
        let min = new Date(this.ano, 11, 1, 12, 30, 0, 0);
        let msg = "O horario de inicio tem que ser entre 7:00 e 12:30";
        if (this.modal.intervalo == "intervalo2") {
          max = new Date(this.ano, 11, 1, 12, 0, 0, 0);
          min = new Date(this.ano, 11, 1, 20, 0, 0, 0);
          msg = "O horario de inicio tem que ser entre 12:00 e 20:00";
        }
        let inicio = new Date(this.ano, 11, 1, this.inicio.getHours(), this.inicio.getMinutes(), 0, 0);
        let fim = new Date(this.ano, 11, 1, this.fim.getHours(), this.fim.getMinutes(), 0, 0);

        this.configuracao.diasDaSemana[this.modal.dia][this.modal.intervalo].inicio = new Date(this.ano, 11, 1, this.inicio.getHours(), this.inicio.getMinutes(), 0, 0);
        this.configuracao.diasDaSemana[this.modal.dia][this.modal.intervalo].fim = new Date(this.ano, 11, 1, this.fim.getHours(), this.fim.getMinutes(), 0, 0);
        this.inicio = new Date(this.ano, 11, 1, 0, 0, 0, 0);
        this.fim = new Date(this.ano, 11, 1, 0, 0, 0, 0);
        this.modal.obj.hide();


        //if ((inicio >= max && inicio <= min) && (fim >= max && fim <= min)) {
        //  this.configuracao.diasDaSemana[this.modal.dia][this.modal.intervalo].inicio = new Date(this.ano, 11, 1, this.inicio.getHours(), this.inicio.getMinutes(), 0, 0);
        //  this.configuracao.diasDaSemana[this.modal.dia][this.modal.intervalo].fim = new Date(this.ano, 11, 1, this.fim.getHours(), this.fim.getMinutes(), 0, 0);
        //  this.inicio = new Date(this.ano, 11, 1, 0, 0, 0, 0);
        //  this.fim = new Date(this.ano, 11, 1, 0, 0, 0, 0);
        //  this.modal.obj.hide();
        //} else {
        //  this.alerta(2, "Intervalo1 de tempo", msg)
        //}
      } else if (this.inicio >= this.fim) {
        this.alerta(2, "intervalo", "O inicio não pode ser maior ou igual que o fim");
      }
    }
    this.configuracao = JSON.parse(JSON.stringify(this.configuracao));
  }
  cancelar() {
    this.modal.obj.hide();
  }
  setOperadoras(op) {
    this.operadoras = op;
  }
  salvarOperadoras() {
    // this.salvarUsuario.emit(this.usuario);
  }
  apagarItem(id) {
    this.dados.splice(id, 1);
    this.configuracao.diaEspecificoDeTrabalho = JSON.parse(JSON.stringify(this.dados));
    this.salvar();
  }
  zerar(dados) {
    this.inicio = new Date(this.ano, 11, 1, 0, 0, 0, 0);
    this.fim = new Date(this.ano, 11, 1, 0, 0, 0, 0);
  }
  salvar() {
    let dados = {
      configuracao: this.configuracao,
      usuario: this.usuario
    }
    this.salvarUpUsuarioConfiguracao(dados);
    this.flagEditarDia = false;
  }
  salvarUpUsuarioConfiguracao(dados) {

    let usuario = dados.usuario;
    usuario['configuracao'] = JSON.stringify(dados.configuracao);

    if (usuario.operadoras != null) {
      if (Array.isArray(usuario.operadoras)) {
        usuario.operadoras = usuario.operadoras.join();
      }
    }
    let post = {
      opt: 'updateUsuario',
      usuario: usuario,
      token: this.token,
      controle: 1
    };
    this.ws.requerimento(post).then((dados) => {
      if (dados[0] == "00000" && dados[1] == null && dados[2] == null) {
        //this.carregaUsuarios();
        this.alerta(0, "Usuario", "Alterado com sucesso!");
        let date = new Date();
        let strData = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
        this.storage.setItem('usuario', Object.assign({ reg: strData }, usuario)).subscribe(() => { });
        let post = {
          configuracao: JSON.stringify(this.configuracao),
          idUsuario: this.usuario.idUsuario,
          idPrestador: this.usuario.idUsuario,
          navegador: navigator.userAgent,
          token: this.token,
          opt: 'logAgendaUsuario'
        }
        this.ws.requerimento(post);
      }
      else {
        this.alerta(1, "Usuario", "Houve algum erro tente novamente.");
      }
    });

  }
  public isString(inputText) {
    if (typeof inputText === 'string' || inputText instanceof String) {
      //it is string
      return true;
    } else {
      //it is not string
      return false;
    }
  }
  public rmDiaEspecificoDeTrabalho(dia) {
    this.configuracao.diaEspecificoDeTrabalho = this.configuracao.diaEspecificoDeTrabalho.filter(item => item != dia);
    this.salvar();
  }
  public flagEditarDia = false;

  public editDiaEspecificoDeTrabalho(dia) {


    if (this.isString(dia.intervalo1.inicio)) {
      dia.intervalo1.inicio = new Date(dia.intervalo1.inicio)
      dia.intervalo1.fim = new Date(dia.intervalo1.fim)
      dia.intervalo2.inicio = new Date(dia.intervalo2.inicio)
      dia.intervalo2.fim = new Date(dia.intervalo2.fim)
    }
    this.editarDiaEspecifico = dia;
    this.diaEspecifico = dia;
    this.flagEditarDia = true;

  }

  public addDia() {
    this.configuracao.diaEspecifico.push(this.bsValue);
    this.salvar();
  }
  public rmDia(dia) {
    this.configuracao.diaEspecifico = this.configuracao.diaEspecifico.filter(item => item != dia);
    this.salvar();
  }
  public rmIntervalo(intervalo) {
    this.configuracao.intervalo = this.configuracao.intervalo.filter(item => item != intervalo);
    this.salvar();
  }
  public addIntervalo() {
    this.configuracao.intervalo.push(this.bsRangeValue);
    this.salvar();
  }
  public update(): void {
    let d = new Date();
    d.setHours(14);
    d.setMinutes(0);
    this.mytime = d;
  };
  public changed(): void { };
  public clear(): void {
    this.mytime = void 0;
  };

  public salvarDiaEspecifico() {
    let existeNaLista = false;
    let diaEscolhido = [
      this.diaEspecifico.data.getDate(),
      this.diaEspecifico.data.getMonth() + 1,
      this.diaEspecifico.data.getFullYear(),
    ].join('/')

    for (let item of this.dados) {
      let dia = new Date(item['data']);
      if ([dia.getDate(), dia.getMonth() + 1, dia.getFullYear(),].join('/') == diaEscolhido) {
        existeNaLista = true;
      }

    }

    let intervalo1 = {
      inicio: {
        horas: this.diaEspecifico.intervalo1.inicio.getHours(),
        minutos: this.diaEspecifico.intervalo1.inicio.getMinutes()
      },
      fim: {
        horas: this.diaEspecifico.intervalo1.fim.getHours(),
        minutos: this.diaEspecifico.intervalo1.fim.getMinutes()
      }
    }

    let intervalo2 = {
      inicio: {
        horas: this.diaEspecifico.intervalo2.inicio.getHours(),
        minutos: this.diaEspecifico.intervalo2.inicio.getMinutes()
      },
      fim: {
        horas: this.diaEspecifico.intervalo2.fim.getHours(),
        minutos: this.diaEspecifico.intervalo2.fim.getMinutes()
      }
    }
    if (!existeNaLista) {
      if (intervalo1.inicio.horas < intervalo1.fim.horas || (intervalo1.inicio.horas == intervalo1.fim.horas && intervalo1.inicio.minutos < intervalo1.fim.minutos)) {
        if (intervalo2.inicio.horas < intervalo2.fim.horas || (intervalo2.inicio.horas == intervalo2.fim.horas && intervalo2.inicio.minutos < intervalo2.fim.minutos) || (intervalo2.inicio.horas == 0 && intervalo2.inicio.minutos == 0 && intervalo2.fim.horas == 0 && intervalo2.fim.minutos == 0)) {
          if (intervalo1.fim.horas < intervalo2.inicio.horas || (intervalo1.fim.horas == intervalo2.inicio.horas && intervalo1.fim.minutos < intervalo2.inicio.minutos) || (intervalo2.inicio.horas == 0 && intervalo2.inicio.minutos == 0 && intervalo2.fim.horas == 0 && intervalo2.fim.minutos == 0)) {
            this.diaEspecifico.duracaoIntervalo2 = this.diaEspecifico.duracaoIntervalo1;
            this.dados.push(JSON.parse(JSON.stringify(this.diaEspecifico)));
            this.configuracao.diaEspecificoDeTrabalho = JSON.parse(JSON.stringify(this.dados));
            this.ajusteDosDadosNoArray(this.configuracao.diaEspecificoDeTrabalho);
            this.salvar();
            this.alerta(0, "Dia de trabalho", "Dia Salvo com sucesso.");
          } else {
            this.alerta(2, "Intervalos", "O horário do intervalo 1 não pode ser maior ou igual que o horário do intervalo 2.");
          }
        } else {
          this.alerta(2, "Intervalo 2", "O horário de inicio não pode ser maior que o horário final.");
        }
      } else {
        this.alerta(2, "Intervalo 1", "O horário de inicio não pode ser maior ou igual ao horário final.");
      }
    } else {
      this.alerta(2, "Dia escolhido", "Esse dia ja foi salvo.");
    }
  }

}
