/*
"1"=> Administrativo
"2"=> Secretária     
"3"=> Nutricionista  
"4"=> Psicóloga      
"5"=> Médico         
"6"=> Enfermeira     
"7"=> Educador Físic
"8"=> Exame          
"9"=> NEO virtua  
*/
 


export const navRecepcao = [
    {
        name: 'Agenda',
        url: '/agenda',
        icon: 'cui-calendar',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Paciente',
        url: '/paciente',
        icon: 'icon-people',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Usuário / Exame',
        url: '/usuario',
        icon: 'icon-user',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Operadora',
        url: '/operadora',
        icon: 'icon-briefcase',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    }, {
        name: 'Configurações',
        url: '/configuracoes',
        icon: 'cui-settings',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Emails',
        url: '/email',
        icon: 'fa fa-table',
        badge: {
            variant: 'info'
        }
    },
    {
        name: 'Relatorio',
        url: '/relatorio',
        icon: 'fa fa-table',
        badge: {
            variant: 'info'
        }
    }
];
export const navExames = [
    {
        name: 'Agenda',
        url: '/agenda',
        icon: 'cui-calendar',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Paciente',
        url: '/paciente',
        icon: 'icon-people',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Relatorio',
        url: '/relatorio',
        icon: 'fa fa-table',
        badge: {
            variant: 'info'
        }
    }
];
export const navNeoVirtua = [
    {
        name: 'Agenda',
        url: '/agenda',
        icon: 'cui-calendar',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Paciente',
        url: '/paciente',
        icon: 'icon-people',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Relatorio',
        url: '/relatorio',
        icon: 'fa fa-table',
        badge: {
            variant: 'info'
        }
    }
];
export const navNutricionista = [
    {
        name: 'Agenda',
        url: '/agenda',
        icon: 'cui-calendar',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Paciente',
        url: '/paciente',
        icon: 'icon-people',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Relatorio',
        url: '/relatorio',
        icon: 'fa fa-table',
        badge: {
            variant: 'info'
        }
    },
    {
        name: 'Material de apoio',
        url: '/artigo',
        icon: 'cui-layers',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    }

];
export const navPsicologo = [
    {
        name: 'Agenda',
        url: '/agenda',
        icon: 'cui-calendar',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Paciente',
        url: '/paciente',
        icon: 'icon-people',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    }
];
export const navPersonal = [
    {
        name: 'Agenda',
        url: '/agenda',
        icon: 'cui-calendar',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Paciente',
        url: '/paciente',
        icon: 'icon-people',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    }
];
export const navAdministrador = [
    {
        name: 'Agenda',
        url: '/agenda',
        icon: 'cui-calendar',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Paciente',
        url: '/paciente',
        icon: 'icon-people',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Relatorio',
        url: '/relatorio',
        icon: 'fa fa-table',
        badge: {
            variant: 'info'
        }
    },
    {
        name: 'Configurações',
        url: '/configuracoes',
        icon: 'cui-settings',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Usuário / Exame',
        url: '/usuario',
        icon: 'icon-user',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    }

];

export const navItems = [

    {
        name: 'Agenda',
        url: '/agenda',
        icon: 'cui-calendar',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Paciente',
        url: '/paciente',
        icon: 'icon-people',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Usuário / Exame',
        url: '/usuario',
        icon: 'icon-user',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Dieta',
        url: '/dieta',
        icon: 'icon-speedometer',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Operadora',
        url: '/operadora',
        icon: 'icon-briefcase',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Empresa',
        url: '/empresa',
        icon: 'fa fa-bank',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Material de apoio',
        url: '/artigo',
        icon: 'cui-layers',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    }, {
        name: 'Configurações',
        url: '/configuracoes',
        icon: 'cui-settings',
        badge: {
            variant: 'info',
            //TEXT: 'NEW'
        }
    },
    {
        name: 'Emails',
        url: '/email',
        icon: 'fa fa-table',
        badge: {
            variant: 'info'
        }
    },
    {
        name: 'Relatorio',
        url: '/relatorio',
        icon: 'fa fa-table',
        badge: {
            variant: 'info'
        }
    },
    //{
    //    name: 'Arquivos',
    //    url: '/arquivos',
    //    icon: 'fa fa-table',
    //    badge: {
    //        variant: 'info'
    //    }
    //}
];
